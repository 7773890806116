const questions = [
    {
        id: 1,
        question: "What is the main idea of Haterville?",
        answer: "The main goal of this project has been to create a social network just for fun. Nowadays, most of social networks have so strict rules where it's hard to express yourself and if you're in a bad mood, want to scream at someone and you do it let's say on the Facebook then you most likely will be banned. In Haterville it's not always about being angry and a big dick, it's more about being funny and sarcastic person, or even if you feel a little bit toxic yourself today - nooo problem, express yourself- it's absolutely fine here :) so we hope you enjoy it!"
    },
    {
        id: 2,
        question: "This app is gorgeous! Where can I find the creator of this app?",
        answer: "Thank you, we appreciate your enthusiasm! You can find me on Instagram, just follow the link below ;>",
        link: "https://www.instagram.com/nastyaemo4"
    },
    {
        id: 3,
        question: "I hate your app.",
        answer: "Thanks for the compliment! We also hate you <3",
    },
    {
        id: 4,
        question: "Why the profile images are prepared in advance? Why can't I pick one of those with my face or my kitten?",
        answer: "First of all, images have been customly designed by its author especially for Haterville. This is the only and main point."
    },
    {
        id: 5,
        question: "I love this app but I feel like nobody loves me and the life is sober and I want to grow some bangs.... What should I do? :(",
        answer: "Looks like you need some space to share your private thoughts.... Don't worry! I know how to deal with your problem. Follow the link below and it will bring you to the quiet space where you can cry over your life and those annoying guys who surround you <3",
        link: "https://emome.qamachine.com/"
    },
    {
        id: 6,
        question: "I've found a problem ;)",
        answer: "Congratulations, live with it or let us know ;)"
    }
]

export default questions